"use client";

import { handleLogin } from "@/app/actions/login";
import { useFormState, useFormStatus } from "react-dom";
import { Small } from "../../components/shared/tags/TextElements";
import Link from "next/link";
import { CHECKOUT_LINK, HOME_LINK } from "@/app/constants";
import { Check } from "@/app/svg/icons/check/Check";
import { useEffect, useState } from "react";
import { useRouter } from "@/navigation";
import clsx from "clsx";
import { useQueryClient } from "@tanstack/react-query";

const initialState = {
  error: null,
  success: false,
};

function clientAction(_: any, formData: FormData) {
  return handleLogin(formData);
}

export default function LoginForm() {
  const [state, formAction] = useFormState(clientAction, initialState);
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (state.error) {
      setError(state.error);
    }
    if (state.success) {
      queryClient.invalidateQueries({ queryKey: ["getUserMeta"] });
    }
  }, [queryClient, state]);

  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-2xl font-bold">Login</h1>
      <form action={formAction} className="flex flex-col gap-4">
        <input
          type="email"
          name="email"
          placeholder="Email"
          required
          className="input input-bordered w-full"
          onChange={() => setError(null)}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          required
          className="input input-bordered w-full"
          onChange={() => setError(null)}
        />
        <SubmitButton hasSucceeded={state.success} />
      </form>
      {error && <p className="mt-2 text-error">{error}</p>}
      <Small>
        <Link className="link" href="/forgot-password">
          Forgot password?
        </Link>
      </Small>
      <Small>
        <Link className="link" href={CHECKOUT_LINK}>
          Sign up
        </Link>{" "}
        or{" "}
        <Link className="link" href={HOME_LINK}>
          Learn more
        </Link>
      </Small>
    </div>
  );
}

function SubmitButton({ hasSucceeded = false }) {
  const { pending } = useFormStatus();
  const router = useRouter();

  useEffect(() => {
    if (hasSucceeded) {
      setTimeout(() => {
        router.back();
      }, 2000);
    }
  }, [hasSucceeded, router]);

  return (
    <button
      type="submit"
      className={clsx(
        "btn",
        "btn-primary",
        "mt-4",
        hasSucceeded && "pointer-events-none",
      )}
      disabled={pending}
    >
      {pending ? (
        <span className="loading loading-spinner loading-md" />
      ) : hasSucceeded ? (
        <>
          Success!
          <Check />
        </>
      ) : (
        "Login"
      )}
    </button>
  );
}
